import React from "react"
import Layout from "../components/Layout"
import Head from "../components/Head"
import "../styles/restaurantes.css"

import crucesSede1 from "../../static/assets/images/restaurants/CRUCES_PRINCIPAL.jpg"
import crucesSede2 from "../../static/assets/images/restaurants/CRUCES_DOS.jpg"
import dianaTurbay from "../../static/assets/images/restaurants/DianaTurbay.jpg"
import JulioSede1 from "../../static/assets/images/restaurants/20_JULIO_UNO.jpg"
import santaLibrada from "../../static/assets/images/restaurants/SANTA_LIBRADA.jpeg"

const restaurantes = () => (
  <>
    <Head
      description="Ven y visitamos en nuestros restaurantes, tendrás un excelente lugar para ti y tu familia, te atenderemos de la mejor manera."
      title="Restaurantes - Asadero RoqueFer"
    />
    <Layout>
      <main>
        <div className="restaurants-info">
          <h2>NUESTROS PUNTOS</h2>
          <p>
            Ven y visítanos en cualquiera de nuestros cuatro puntos, contamos
            con todas las medidas y protocolos de bioseguridad. Te atenderemos
            de 11:00 am a 10:00pm
          </p>
          <div className="restaurant">
            <p className="restaurant-title">Las Cruces</p>
            <p>
              <strong>Dirección:</strong> Cl 2 # 7-38
            </p>
            <div className="restaurant-location">
              <img
                src={crucesSede1}
                alt="Fotografía del restaurante de las cruces sede 1"
                className="restaurant-image"
              />
              <iframe
                title="Ubicación en el mapa de asadero roquefer"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1182.3774834776716!2d-74.0812709976387!3d4.589007099615481!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9901a65d3df5%3A0xf4e1cb27c2d7ee57!2zQ2wuIDIgIzctMzgsIEJvZ290w6E!5e0!3m2!1sen!2sco!4v1618276320564!5m2!1sen!2sco"
                width="100%"
                height="310px"
                loading="lazy"
              ></iframe>
            </div>
          </div>
          <div className="restaurant">
            <p className="restaurant-title">Las Cruces Punto 2</p>
            <p>
              <strong>Dirección:</strong> Cl 2 # 6-98
            </p>
            <div className="restaurant-location">
              <img
                src={crucesSede2}
                alt="Fotografía del restaurante de las cruces sede 2"
                className="restaurant-image"
              />
              <iframe
                title="Ubicación en el mapa de asadero roquefer"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d994.2568050951163!2d-74.0809258707855!3d4.589137936597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9901b2946dd1%3A0x7ce2f847cb4ede0e!2zQ2wuIDIgIzYtOTgsIEJvZ290w6E!5e0!3m2!1ses!2sco!4v1618276644561!5m2!1ses!2sco"
                width="100%"
                height="310px"
                loading="lazy"
              ></iframe>
            </div>
          </div>
          <div className="restaurant">
            <p className="restaurant-title">Diana Turbay</p>
            <p>
              <strong>Dirección:</strong> Carrera 5 # 48T Sur - 39
            </p>
            <div className="restaurant-location">
              <img
                src={dianaTurbay}
                alt="Fotografía del restaurante del diana turbay"
                className="restaurant-image"
              />
              <iframe
                title="Ubicación en el mapa de asadero roquefer"
                src="https://www.google.com/maps/embed?pb=!3m2!1sen!2sco!4v1670723107917!5m2!1sen!2sco!6m8!1m7!1s0_Uvuc3uK6liYoQYOv9OIQ!2m2!1d4.548814190876405!2d-74.10935386023131!3f353.042583142446!4f0.05367462956344582!5f0.7820865974627469"
                width="100%"
                height="310px"
                loading="lazy"
              ></iframe>
            </div>
          </div>
          <div className="restaurant">
            <p className="restaurant-title">20 de Julio</p>
            <p>
              <strong>Dirección:</strong> Cra 8 # 26-03 sur
            </p>
            <div className="restaurant-location">
              <img
                src={JulioSede1}
                alt="Fotografía del restaurante en el 20 de julio sede 1"
                className="restaurant-image"
              />
              <iframe
                title="Ubicación en el mapa de asadero roquefer"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3977.248900283305!2d-74.11187752425012!3d4.549176342950862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f98a70ab65977%3A0x23406cdbb44351af!2sCra.%205%20%2348t%20Sur23%2C%20Bogot%C3%A1!5e0!3m2!1sen!2sco!4v1670721881382!5m2!1sen!2sco"
                width="100%"
                height="310px"
                loading="lazy"
              ></iframe>
            </div>
          </div>
          <div className="restaurant">
            <p className="restaurant-title">Santa Librada</p>
            <p>
              <strong>Dirección:</strong> Cl 74 c sur # 14 70
            </p>
            <div className="restaurant-location">
              <img
                src={santaLibrada}
                alt="Fotografía del restaurante en santalibrada"
                className="restaurant-image"
              />
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d497.17964934891154!2d-74.1165019289244!3d4.514955827911805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3fa22ec2cea4d9%3A0xa67e0a5938c13b4!2sCl.%2074c%20Sur%20%2314-19%2C%20Bogot%C3%A1!5e0!3m2!1sen!2sco!4v1629089097937!5m2!1sen!2sco"
                width="100%"
                height="310px"
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  </>
)

export default restaurantes
